import { findIconDefinition, icon } from '@fortawesome/fontawesome-svg-core'

function bind (el, binding) {
  let maskEl = el.maskEl

  if (!binding.value) {
    if (maskEl) {
      maskEl.remove()
    }
    return
  }

  if (!maskEl) {
    maskEl = el.maskEl = document.createElement('div')
    maskEl.className = 'v-mask'

    const maskContentsEl = document.createElement('div')
    maskContentsEl.className = 'v-mask-contents'
    maskEl.appendChild(maskContentsEl)

    // create placeholder icon until we actually replace it with real fontawesome element
    const iconEl = document.createElement('i')
    maskContentsEl.appendChild(iconEl)

    const textEl = document.createElement('span')
    textEl.className = 'v-mask-text'
    maskContentsEl.appendChild(textEl)

    maskEl.addEventListener('click', () => {
      if (maskEl.clearOnClick) {
        maskEl.remove()
      }
    })
  }

  let iconName = null
  let classes = []
  let text = null
  let clearOnClick = false
  let parentEl = el

  if (binding.value instanceof Object) {
    if (binding.value.mode) {
      switch (binding.value.mode) {
        case 'loading':
          iconName = 'circle-notch'
          classes = ['fa-spin']
          text = 'Loading...'
          break
        case 'updating':
          iconName = 'circle-notch'
          classes = ['fa-spin']
          text = 'Updating...'
          break
        case 'info':
          iconName = 'circle-info'
          break
        case 'error':
          iconName = 'triangle-exclamation'
          text = 'There was an error'
          clearOnClick = true
          break
        default:
          iconName = binding.value.icon
      }
    }
    // use `||` pattern to fallback to mode
    text = binding.value.text || text
    iconName = binding.value.icon || iconName
    classes = binding.value.classes || classes
    clearOnClick = typeof binding.value.clearOnClick === 'boolean' ? binding.value.clearOnClick : clearOnClick
    parentEl = (binding.value.selector && el.querySelector(binding.value.selector)) || parentEl
  }

  const maskContentsEl = maskEl.querySelector('.v-mask-contents')

  if (iconName || text) {
    const iconEl = maskContentsEl.querySelector(':first-child')
    const textEl = maskContentsEl.querySelector('.v-mask-text')

    if (iconName) {
      const iconDefinition = findIconDefinition({ prefix: 'fas', iconName })
      const newIconEl = icon(iconDefinition, { classes }).node[0]
      maskContentsEl.replaceChild(newIconEl, iconEl)
    } else {
      iconEl.style.display = 'none'
    }

    if (text) {
      textEl.textContent = text
      textEl.style.removeProperty('display')
    } else {
      textEl.style.display = 'none'
    }

    maskContentsEl.style.removeProperty('display')
  } else {
    maskContentsEl.style.display = 'none'
  }

  maskEl.clearOnClick = clearOnClick

  parentEl.appendChild(maskEl)
}

export const Mask = {
  beforeMount: bind,
  updated: bind,
}
