import reusePromise from 'reuse-promise'
import _ from 'lodash'
import restClient from '@/services/clients/rest'
import { extractErrorMessage } from '@/utils/misc'
import crudServiceFactory from '@/views/notification/NotificationMessageService'

const moduleName = 'dashboardNotifications'

async function retrieveDashboardNotifications (showAllOrgs) {
  try {
    const response = await restClient.get('dashboard/notifications', { params: (showAllOrgs === false ? { multiOrg: 'no' } : undefined) })
    return response.data
  } catch (error) {
    console.warn('Error retrieving dashboard notifications', error)
    throw error
  }
}
const getReusedPromise = reusePromise(retrieveDashboardNotifications)

function getShowAllOrgsPreference (userId) {
  return localStorage.getItem(`USER_${userId}_SHOW_ALL_ORGS`) !== 'false'
}

function setShowAllOrgsPreference (userId, showAllOrgs) {
  return localStorage.setItem(`USER_${userId}_SHOW_ALL_ORGS`, (showAllOrgs !== false).toString())
}

export function registerDashboardNotificationsWithStore (store) {
  if (store.state[moduleName]) throw new Error(`${moduleName} module already registered with store`)

  store.registerModule(moduleName, {
    namespaced: true,
    state () {
      return {
        running: false,
        loading: false,
        timeOffRequests: null,
        missedPunchRequests: null,
        disciplineInfractions: null,
        disciplineEvents: null,
        notificationMessages: null,
        notificationMessageItems: [],
        notificationMessageItemsLoaded: false,
        notificationMessageItemsLoadPromise: null,
        notificationMessageItemsLoadLastError: null,
        showAllOrgs: true
      }
    },
    getters: {
      summaryCountDisplay: state => {
        let count = 0
        let hasMore = false

        if (state.timeOffRequests) {
          count += state.timeOffRequests.count
          hasMore ||= state.timeOffRequests.hasMore
        }
        if (state.missedPunchRequests) {
          count += state.missedPunchRequests.count
          hasMore ||= state.missedPunchRequests.hasMore
        }
        if (state.disciplineInfractions) {
          count += state.disciplineInfractions.count
          hasMore ||= state.disciplineInfractions.hasMore
        }
        if (state.disciplineEvents) {
          count += state.disciplineEvents.count
          hasMore ||= state.disciplineEvents.hasMore
        }
        if (state.notificationMessages) {
          count += state.notificationMessages.count
          hasMore ||= state.notificationMessages.hasMore
        }

        return count ? `${count}${hasMore ? '+' : ''}` : null
      },
      timeOffRequestCount: state => _.get(state.timeOffRequests, 'count') || 0,
      missingPunchRequestCount: state => _.get(state.missedPunchRequests, 'count') || 0,
      disciplineInfractionCount: state => _.get(state.disciplineInfractions, 'count') || 0,
      disciplineEventCount: state => _.get(state.disciplineEvents, 'count') || 0,
      notificationMessageCount: state => state.notificationMessages?.count ?? 0,
      requestCountDisplay: state => type => {
        const data = state[type]
        const count = _.get(data, 'count')
        return count ? `${count}${data.hasMore ? '+' : ''}` : null
      },
      timeOffRequestCountDisplay: (state, getters) => getters.requestCountDisplay('timeOffRequests'),
      missingPunchRequestCountDisplay: (state, getters) => getters.requestCountDisplay('missedPunchRequests'),
      disciplineInfractionCountDisplay: (state, getters) => getters.requestCountDisplay('disciplineInfractions'),
      disciplineEventCountDisplay: (state, getters) => getters.requestCountDisplay('disciplineEvents'),
      notificationMessageCountDisplay: (state, getters) => getters.requestCountDisplay('notificationMessages'),
      notificationMessageItemsLoading: state => !!state.notificationMessageItemsLoadPromise,
      notificationMessageCrudService: (state, getters, rootState) => crudServiceFactory(
        rootState.organizationId,
        rootState.orgUserId,
        rootState.userProfile.userId
      )
    },
    actions: {
      start (context) {
        const showAllOrgs = getShowAllOrgsPreference(context.rootState.userProfile.userId)
        context.commit('start', { showAllOrgs })
        context.dispatch('load')
        context.dispatch('notificationMessageMasterDetail/filter/setFilterParams', { multiOrg: showAllOrgs === false ? 'no' : 'yes' }, { root: true })
      },
      stop (context) {
        context.commit('stop')
      },
      async load (context) {
        if (!context.state.running) return Promise.resolve({})
        const promise = getReusedPromise(context.state.showAllOrgs)
        if (context.state.loading) return promise
        context.commit('startLoad')
        let data
        try {
          data = await promise
        } catch (e) {
          context.commit('abortLoad')
          // Try again in 5 minutes.
          setTimeout(() => context.dispatch('load'), 5 * 60 * 1000)
          throw e
        }
        context.commit('loaded', data)
        // Refresh in 1 hour.
        setTimeout(() => context.dispatch('load'), 3600 * 1000)
        return promise
      },
      removeNotification (context, { type, id }) {
        const data = context.state[type]
        if (!data) return
        const hasId = data.ids.includes(id)
        const ids = hasId ? data.ids.filter(i => i !== id) : data.ids
        context.commit('updateRequestData', {
          type,
          data: {
            count: ids.length,
            ids,
            hasMore: data.hasMore
          }
        })
        // If necessary, refresh from backend in 5 seconds, after eventual consistency likely settles.
        if (hasId && data.hasMore) {
          setTimeout(() => context.dispatch('load'), 5000)
        }
      },
      addNotification (context, { type, id }) {
        const data = context.state[type]
        if (!data) return
        if (data.hasMore || data.ids.includes(id)) return
        const ids = data.ids.concat([id])
        context.commit('updateRequestData', {
          type,
          data: {
            count: ids.length,
            ids,
            hasMore: false
          }
        })
      },
      async loadNotificationMessageItems (context, forceRefresh) {
        if (context.state.notificationMessageItemsLoaded && !forceRefresh) return Promise.resolve(context.state.notificationMessageItems)
        if (context.state.notificationMessageItemsLoadPromise) return context.state.notificationMessageItemsLoadPromise
        const promise = context.getters.notificationMessageCrudService.list({ limit: 10, multiOrg: context.state.showAllOrgs ? 'yes' : 'no' })
        context.commit('notificationMessageLoadStarted', promise)
        try {
          const data = await promise
          context.commit('notificationMessageLoadFinished', { items: data.results })
        } catch (error) {
          console.warn('Failed to load notification message items', error)
          context.commit('notificationMessageLoadFinished', { error: extractErrorMessage(error) })
        }
        return promise
      },
      async setNotificationMessageUnread (context, { itemId, unread }) {
        await context.getters.notificationMessageCrudService.setMessageUnread(itemId, unread)
        context.commit('setNotificationMessageUnread', { itemId, unread })
        context.dispatch(unread ? 'addNotification' : 'removeNotification', { type: 'notificationMessages', id: itemId })
      },
      async deleteNotificationMessage (context, itemId) {
        await context.getters.notificationMessageCrudService.deleteMessage(itemId)
        context.commit('deleteNotificationMessage', itemId)
        context.dispatch('removeNotification', { type: 'notificationMessages', id: itemId })
      },
      async addNotificationMessage (context, item) {
        context.commit('addNotificationMessage', item)

        const { masterItems, cursor } = context.rootState.notificationMessageMasterDetail
        context.commit('notificationMessageMasterDetail/masterItemsChanged', {
          masterItems: [item, ...masterItems],
          cursor
        }, { root: true })
      },
      setShowAllOrgs (context, showAllOrgs) {
        context.commit('setShowAllOrgs', showAllOrgs)
        setShowAllOrgsPreference(context.rootState.userProfile.userId, showAllOrgs)
        context.dispatch('load')
        context.dispatch('notificationMessageMasterDetail/filter/setFilterParams', { multiOrg: showAllOrgs === false ? 'no' : 'yes' }, { root: true })
        context.dispatch('loadNotificationMessageItems', true)
      }
    },
    mutations: {
      start (state, { showAllOrgs }) {
        state.running = true
        state.showAllOrgs = showAllOrgs
      },
      stop (state) {
        state.running = false
      },
      startLoad (state) {
        state.loading = true
      },
      abortLoad (state) {
        state.loading = false
      },
      loaded (state, data) {
        state.timeOffRequests = data.timeOffRequests
        state.missedPunchRequests = data.missedPunchRequests
        state.disciplineInfractions = data.disciplineInfractions
        state.disciplineEvents = data.disciplineEvents
        state.notificationMessages = data.notificationMessages
        state.loading = false
      },
      updateRequestData (state, { type, data }) {
        state[type] = data
      },
      notificationMessageLoadStarted(state, promise) {
        state.notificationMessageItemsLoadPromise = promise
        state.notificationMessageItemsLoaded = false
        state.notificationMessageItemsLoadLastError = null
      },
      notificationMessageLoadFinished(state, { items, error }) {
        state.notificationMessageItemsLoadPromise = null
        state.notificationMessageItems = items ?? null
        state.notificationMessageItemsLoadLastError = error ?? null
        state.notificationMessageItemsLoaded = !error
      },
      setNotificationMessageUnread (state, { itemId, unread }) {
        const item = state.notificationMessageItems.find(i => i.id === itemId)
        if (item) item.unread = unread
      },
      deleteNotificationMessage (state, itemId) {
        state.notificationMessageItems = state.notificationMessageItems.filter(i => i.id !== itemId)
      },
      addNotificationMessage (state, item) {
        state.notificationMessageItems = [item, ...state.notificationMessageItems]
      },
      setShowAllOrgs (state, showAllOrgs) {
        state.showAllOrgs = showAllOrgs
      }
    }
  })

  setTimeout(() => {
    store.watch(
      (state, getters) =>
        getters.orgReady &&
        getters.insideOrgNamespace &&
        (getters.canManageTimeOff || (state.enableSubmitMissedPunch && getters.canEditPunchTimes) || getters.disciplineEnabled || getters.userNotificationsEnabled),
      enabled => {
        store.dispatch(`${moduleName}/${enabled ? 'start' : 'stop'}`)
      }
    )
  })
}
