import axios from 'axios'
import constants from '@/constants'

const version = constants().version
const checkInterval = 5 * 60 * 1000 // every 5 minutes

let running = false
let timerId = null

async function getMinVersion () {
  try {
    const response = await axios.get('/version.json')
    return response.data ? response.data.minVersion : null
  } catch (error) {
    console.warn('Error getting minimum version', error)
    throw error
  }
}

async function runTask () {
  try {
    const minVersion = await getMinVersion()

    if (!running) {
      return
    }

    // TODO: Should we do version !== minVersion in order to support rollback?
    if (minVersion && version < minVersion) {
      window.location.reload(true)
    }
  } catch (error) {
    // ignore
  } finally {
    if (running) {
      timerId = setTimeout(runTask, checkInterval)
    }
  }
}

export default {
  start () {
    running = true
    runTask()
  },
  stop () {
    if (timerId) {
      clearTimeout(timerId)
      timerId = null
    }
    running = false
  }
}
