import _ from 'lodash'

function width () {
  return window.innerWidth
}

function height () {
  return window.innerHeight
}

function initSizeMonitor (store) {
  const state = {
    clientWidth: width(),
    clientHeight: height()
  }

  store.registerModule('size', {
    namespaced: true,
    state () {
      return state
    },
    mutations: {
      resize (state, { width, height }) {
        state.clientWidth = width
        state.clientHeight = height
      }
    }
  })

  window.addEventListener('resize', _.debounce(() => {
    store.commit('size/resize', { width: width(), height: height() })
  }), 50)
}

export {
  initSizeMonitor
}
