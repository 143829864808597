<template>
  <b-modal
    v-bind="$attrs"
    title="Getting Started"
    hide-footer
    no-close-on-backdrop
    no-fade
    centered
  >
    <h6>Welcome to Fareclock.</h6><br/>
    <p>
      You are now looking at the <b>Fareclock Console</b>, designed for administrators to manage the account.
    </p>
    <p>
      The first thing you will want to do is install the time clock app on your phone or tablet device:
    </p>
    <p class="app-stores">
      <a href="https://itunes.apple.com/us/app/fareclock-2/id1017185096" target="_fareclock_apple_app_store">
        <img src="/img/apple-store@2x.png" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.fareclock.tc" target="_fareclock_google_play_store">
        <img src="/img/play-store@2x.png" />
      </a>
    </p>
    <p>
      After you install the app on your phone or tablet, log in there and set up the time clock.
      You will be able to add your employees on the time clock. You can also add employees here in the Console as well.
    </p>
  </b-modal>
</template>
<script>
export default {
  name: 'WelcomeMessage',
  inheritAttrs: false
}
</script>
<style lang="scss" scoped>
  .app-stores {
    text-align: center;
    > * {
      margin: 5px;
    }
    img {
      width: 150px;
    }
  }
</style>
