
import { auth } from './clients/firebase'
import { inMemoryPersistence, onAuthStateChanged, onIdTokenChanged, setPersistence, signInWithCustomToken } from 'firebase/auth'
import loginClient from './clients/login'
import restClient from './clients/rest'
import constants from '@/constants'
import reusePromise from 'reuse-promise'
import _ from 'lodash'
import { jwtDecode } from 'jwt-decode'

const loginServer = constants().loginServer
// We will refresh derived jwt's 5 minutes before expiration to match Firebase Auth behavior.
// https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/core/user/id_token_result.ts
const REFRESH_BUFFER_SECONDS = 5 * 60

function generateIdaasJwtPromiseFactory () {
  return Promise.resolve()
    .then(() => {
      if (auth.currentUser) {
        return auth.currentUser
      } else {
        return loginClient.post('createTokenForSession')
          .then(response => response.data.token)
          .then(token => signInWithCustomToken(auth, token))
          .then(userCredential => userCredential.user)
      }
    })
    .then(user => user.getIdToken())
    .catch(error => {
      console.warn('Failed to generate jwt', error)

      // if auth failure, then redirect to login
      if ((error.code && error.code.startsWith('auth/')) ||
          (error.response && [400, 401, 403].includes(error.response.status))) {
        window.location.href = `${loginServer}?next=${window.location.href}`
        error.redirecting = true
      }
      return Promise.reject(error)
    })
}
// Promise is only reused while it's pending, i.e., prevents concurrent generation of new promise.
// Once promise is completed, a new call will generate a new promise.
const generateIdaasJwtReusedPromise = reusePromise(generateIdaasJwtPromiseFactory)

export function registerJwtServiceWithStore (store) {
  store.registerModule('jwt', {
    namespaced: true,
    state () {
      return {
        initialized: false,
        user: null,
        idaasClaims: null,
        orgAccessJwt: null,
        orgAccessClaims: null,
        delegateJwt: null,
        delegateClaims: null,
        safeOnlyJwt: null,
        safeOnlyClaims: null
      }
    },
    getters: {
      isDelegate: state => !!state.delegateJwt,
      authHeaderPrefix: state => state.delegateJwt ? 'Delegate' : 'Bearer',
      idaasJwt: state => () => {
        return state.delegateJwt ? Promise.resolve(state.delegateJwt) : generateIdaasJwtReusedPromise()
      },
      orgAccessJwt: (state) => orgId => {
        if (state.delegateJwt) return Promise.resolve(state.delegateJwt)
        if (state.orgAccessClaims && state.orgAccessClaims.exp > Date.now() / 1000 + REFRESH_BUFFER_SECONDS) {
          return Promise.resolve(state.orgAccessJwt)
        }
        return generateOrgAccessJwtReusedPromise(orgId)
      },
      safeOnlyJwt: state => () => {
        if (state.safeOnlyClaims && state.safeOnlyClaims.exp > Date.now() / 1000 + REFRESH_BUFFER_SECONDS) {
          return Promise.resolve(state.safeOnlyJwt)
        }
        return generateSafeOnlyJwtReusedPromise()
      },
      email: state => {
        if (state.delegateClaims) return state.delegateClaims.email
        if (!state.user) return null
        return state.user.email
      },
      emailVerified: state => {
        // Assume delegated user's email is verified.
        if (state.delegateClaims) return true
        if (!state.user) return null
        return state.user.emailVerified
      },
      displayName: state => {
        if (state.delegateClaims) return state.delegateClaims.name
        if (!state.user) return null
        return state.user.displayName
      },
      providerId: state => {
        if (state.delegateClaims) return 'delegate'
        if (!state.user) return null
        // How to get provider id:
        // https://github.com/firebase/FirebaseUI-Android/issues/329
        return state.user.providerData[0].providerId
      },
      mfaVerified: state => {
        return !!state.idaasClaims?.fc_mfa
      }
    },
    actions: {
      initialized (context, data) {
        if (context.state.initialized) return
        const delegateJwt = _.get(data, 'delegateJwt')
        if (delegateJwt) {
          const delegateClaims = parseJwt(delegateJwt)
          context.commit('delegateInitialized', { delegateJwt, delegateClaims})
        } else {
          context.commit('initialized')
        }
      },
      userChanged (context, user) {
        context.commit('userChanged', user)
        if (user && !user.emailVerified) {
          context.commit('fatalErrorDidOccur', {
            fatalErrorTitle: 'Email not verified',
            fatalErrorMsg: 'Your email has not been verified. You should have received an email with a link to verify your account.'
          }, { root: true })
        }
      },
      orgChanged (context, orgId) {
        if (context.state.orgAccessClaims && context.state.orgAccessClaims.fc_org !== orgId) {
          context.commit('invalidateOrg')
        }
      }
    },
    mutations: {
      initialized (state) {
        state.initialized = true
      },
      delegateInitialized (state, data) {
        state.initialized = true
        Object.assign(state, data)
      },
      userChanged (state, user) {
        state.user = user
      },
      idaasJwtChanged (state, idTokenResult) {
        state.idaasClaims = idTokenResult && idTokenResult.claims
        // Invalidate derived jwts.
        state.orgAccessJwt = null
        state.orgAccessClaims = null
        state.safeOnlyJwt = null
        state.safeOnlyClaims = null
      },
      orgAccessJwtChanged (state, { orgAccessJwt, orgAccessClaims }) {
        state.orgAccessJwt = orgAccessJwt
        state.orgAccessClaims = orgAccessClaims
        // Invalidate derived jwt.
        state.safeOnlyJwt = null
        state.safeOnlyClaims = null
      },
      safeOnlyJwtChanged (state, { safeOnlyJwt, safeOnlyClaims }) {
        state.safeOnlyJwt = safeOnlyJwt
        state.safeOnlyClaims = safeOnlyClaims
      },
      invalidateOrg (state) {
        Object.assign(state, {
          orgAccessJwt: null,
          orgAccessClaims: null,
          safeOnlyJwt: null,
          safeOnlyClaims: null,
          delegateJwt: null,
          delegateClaims: null
        })
      }
    }
  })

  // Check if we're running in headless mode.
  // Otherwise, default to the usual firebase auth.
  // Note that we've seen on iOS sometimes that window.localStorage is null.
  const delegateJwt = window.localStorage && window.localStorage.getItem('DELEGATE_JWT')
  if (delegateJwt) {
    store.dispatch('jwt/initialized', { delegateJwt })
  } else {
    initializeFirebaseAuth()
  }

  function initializeFirebaseAuth() {
    // Do not persist authentication state, because
    // we want to refresh it from login domain each time
    // page loads. That gives sign-out more integrity.
    setPersistence(auth, inMemoryPersistence)

    onAuthStateChanged(auth, user => {
      store.dispatch('jwt/initialized')
      store.dispatch('jwt/userChanged', user)
    })

    onIdTokenChanged(auth, user =>
      Promise.resolve()
        .then(() => user && user.getIdTokenResult())
        .then(idTokenResult => store.commit('jwt/idaasJwtChanged', idTokenResult))
    )
  }

  function parseJwt (token) {
    return jwtDecode(token)
  }

  function generateOrgAccessJwtPromiseFactory (orgId) {
    return generateIdaasJwtReusedPromise()
      .then(idaasIdToken => restClient.post(
        'create-org-access-token',
        { token: idaasIdToken },
        {
          orgId,
          // manual auth here using idaasIdToken.
          auth: false,
          headers: { Authorization: `Bearer ${idaasIdToken}` }
        }
      ))
      .then(response => {
        const orgAccessJwt = response.data.jwt
        store.commit('jwt/orgAccessJwtChanged', { orgAccessJwt, orgAccessClaims: parseJwt(orgAccessJwt) })
        return orgAccessJwt
      })
      .catch(error => {
        console.warn('Failed to generate org access jwt', error)
        return Promise.reject(error)
      })
  }
  const generateOrgAccessJwtReusedPromise = reusePromise(generateOrgAccessJwtPromiseFactory)

  function generateSafeOnlyJwtPromiseFactory () {
    return generateOrgAccessJwtReusedPromise()
      .then(() => restClient.post('create-safe-only-jwt'))
      .then(response => {
        const safeOnlyJwt =  response.data.token
        store.commit('jwt/safeOnlyJwtChanged', { safeOnlyJwt, safeOnlyClaims: parseJwt(safeOnlyJwt) })
        return safeOnlyJwt
      })
      .catch(error => {
        console.warn('Failed to generate safe-only jwt', error)
        return Promise.reject(error)
      })
  }
  const generateSafeOnlyJwtReusedPromise = reusePromise(generateSafeOnlyJwtPromiseFactory)
}
