// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue'
import { createBootstrap } from 'bootstrap-vue-next'
import App from './App.vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import router from './router'
import store from './store'
import { guardRouteAccess } from './router/RouterAccessGuard'
import { ClickOutside } from './directives/ClickOutside'
import { Mask } from './directives/Mask'
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import 'moment'
import momentTimezone from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
import { extendMoment } from 'moment-range'
import constants from '@/constants'
import PrimeVue from 'primevue/config'
import { initActivityMonitor } from './services/ActivityMonitorService'
import { initSizeMonitor } from './services/SizeMonitorService'
import { registerJwtServiceWithStore } from './services/JwtService'
import { registerUserProfileWithStore } from './services/UserProfileService'
import { registerOrgServiceWithStore } from './services/OrgService'
import { registerFormatPreferencesWithStore } from './services/FormatPreferencesService'
import { registerDashboardNotificationsWithStore } from './services/NotificationService'
import { i18n } from './i18n-setup'
import FormGroup from './components/form/FormGroup.vue'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import { configureToast } from './utils/toast'
import _ from 'lodash'

// fonts
import "@fontsource/open-sans/latin-400.css"

// Explictly import polyfills that vue cli 3 doesn't automatically polyfill,
// since it uses the older core-js 2. This should be fixed in vue cli 4:
// https://github.com/vuejs/vue-cli/issues/3834#issuecomment-484349452
// import 'core-js/stable/array/flat-map'

Bugsnag.start({
  apiKey: '66df12ae7198d737a549843f2d4492fa',
  plugins: [new BugsnagPluginVue()],
  // TODO??
  // onError: function (error) {}
})

const development = constants().development

// moment-duration-format automatically installs on default moment instance.
// we need to manually install it on moment-timezone.
momentDurationFormatSetup(momentTimezone)
// Set defaults.
Object.assign(momentTimezone.duration.fn.format.defaults, {
  trim: false,
  round: true
})
extendMoment(momentTimezone)

faLibrary.add(fab, far, fas)

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('font-awesome-layers', FontAwesomeLayers)
  .component('font-awesome-layers-text', FontAwesomeLayersText)
  .component('FormGroup', FormGroup)
  .directive('click-outside', ClickOutside)
  .directive('mask', Mask)
  .use(Bugsnag.getPlugin('vue'))
  .use(createBootstrap())
  .use(PrimeVue)
  .use(DialogService)
  .use(ToastService)
  .use(router)
  .use(store)
  .use(i18n)

initActivityMonitor(store)
initSizeMonitor(store)
registerJwtServiceWithStore(store)
registerUserProfileWithStore(store)
registerOrgServiceWithStore(store)
registerFormatPreferencesWithStore(store)
registerDashboardNotificationsWithStore(store)
guardRouteAccess(router, store)

app.mount('#app')

// Provide easy way to append a path in a route-link:
// https://router.vuejs.org/guide/migration/#Removal-of-append-prop-in-router-link-
app.config.globalProperties.append = (path, pathToAppend) =>
  path + (path.endsWith('/') ? '' : '/') + pathToAppend
app.config.globalProperties._$ = _

configureToast(app)

// TODO: Inject the following development code from a separate module using build-time script.
if (development) {
  // Add development tooling to window object.
  import('deep-object-diff').then(module => {
    window.diff = module
  })
  // Add ability to break in debugger if a particular property changes.
  // It became useful to debug SyncFusion.
  // https://stackoverflow.com/a/11658693/1237919
  console.watch = function(oObj, sProp) {
    var sPrivateProp = "$_"+sProp+"_$" // to minimize the name clash risk
    oObj[sPrivateProp] = oObj[sProp]

    // overwrite with accessor
    Object.defineProperty(oObj, sProp, {
        get: function () {
            return oObj[sPrivateProp]
        },

        set: function (value) {
            //console.log("setting " + sProp + " to " + value);
            debugger // sets breakpoint
            oObj[sPrivateProp] = value
        }
    })
  }
}
