
export function configureToast (app) {
  const toast = app.config.globalProperties.$toast

  Object.assign(toast, {
    error (detail, life = 2000) {
      toast.add({ severity: 'error', summary: null, detail, life })
    },
    success (detail, life = 2000) {
      toast.add({ severity: 'success', summary: null, detail, life })
    },
    closeAll () {
      toast.removeAllGroups()
    }
  })
}
