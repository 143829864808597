<template>
  <master-detail
    ref="masterDetail"
    :orchestrator="orchestrator"
    :crudService="crudService"
    :allColumns="allColumns"
    :searchEnabled="false"
    :filterEnabled="false"
    :addItemEnabled="false"
    :importEnabled="false"
    :promptDirtySave="false"
    :getRowClass="getRowClass"
    class="notification-message-list"
    v-bind="routeHooks"
  >
    <template #quick-filter>
      <b-col cols="auto">
        <b-form-checkbox :modelValue="showAllOrgs" switch @update:modelValue="setShowAllOrgs($event)">
          Show all organizations
        </b-form-checkbox>
      </b-col>
    </template>
  </master-detail>
</template>
<script>
import { registerMasterDetailResourceWithStore } from '@/store/MasterDetailOrchestrator'
import store from '@/store'
import AgGridColumnHelpers from '@/mixins/AgGridColumnHelpers'
import MasterDetail from '@/components/grid/MasterDetail.vue'
import RouteHooks from '@/mixins/RouteHooks'
import crudServiceFactory from './NotificationMessageService'
import NotificationMessageActionCellRenderer from './NotificationMessageActionCellRenderer.vue'
import { extractErrorMessage } from '@/utils/misc'
import { mapState } from 'vuex'

const orchestrator = 'notificationMessageMasterDetail'
// TODO: routeName can not support both notification-messages and org-notification-messages at the same time!
const routeName = 'org-notification-messages'
registerMasterDetailResourceWithStore(orchestrator, store, {
  resourceName: 'Notification Message',
  routeName,
  icon: 'bell',
  filterDefaults: () => ({ multiOrg: 'yes' })
})

export default {
  name: 'NotificationMessageList',
  components: {
    MasterDetail,
    NotificationMessageActionCellRenderer
  },
  mixins: [
    AgGridColumnHelpers,
    RouteHooks
  ],
  data () {
    return {
      orchestrator,
      // TODO: Bold face if unread.
      allColumns: [
        {
          columnDef: {
            headerName: 'Timestamp',
            field: 'timestamp',
            valueGetter: this.dateTimeValueGetter,
            comparator: this.dateTimeStringComparator,
          }
        },
        {
          columnDef: {
            headerName: 'Subject',
            field: 'title'
          }
        },
        {
          columnDef: {
            headerName: 'Body',
            field: 'body',
            width: 250
          }
        },
        {
          columnDef: {
            headerName: 'Organization',
            field: 'orgName',
            hide: true
          }
        },
        {
          columnDef: {
            headerName: '',
            field: 'actions',
            cellRenderer: 'NotificationMessageActionCellRenderer',
            cellRendererParams: {
              toggleUnread: this.toggleUnread,
              deleteItem: this.deleteItem
            },
            width: 100
          },
          columnMenuHeaderName: 'Actions'
        }
      ]
    }
  },
  computed: {
    ...mapState('notificationMessageMasterDetail', ['masterItems']),
    ...mapState('dashboardNotifications', ['showAllOrgs']),
    crudService () {
      return crudServiceFactory(this.$store.state.organizationId, this.$store.state.orgUserId, this.$store.state.userProfile.userId)
    },
  },
  watch: {
    masterItems (masterItems) {
      const gridApi = this.$refs.masterDetail?.gridOptions?.api
      if (gridApi) {
        this.$refs.masterDetail.rowData = masterItems
        gridApi.redrawRows()
      }
    },
    showAllOrgs () {
      // refresh
      // TODO: Handle if not mounted.
      this.$refs.masterDetail.search()
    }
  },
  methods: {
    async toggleUnread (params) {
      try {
        const item = params.node.data
        await this.$store.dispatch('dashboardNotifications/setNotificationMessageUnread', { itemId: item.id, unread: !item.unread })
        item.unread = !item.unread
        params.api.redrawRows([params.node])
      } catch (error) {
        this.$toast.error(extractErrorMessage(error))
      }
    },
    async deleteItem (params) {
      try {
        const itemId = params.node.data.id
        await this.$store.dispatch('dashboardNotifications/deleteNotificationMessage', itemId)
        if (this.$refs.masterDetail) this.$refs.masterDetail.itemDeleted(itemId)
      } catch (error) {
        this.$toast.error(extractErrorMessage(error))
      }
    },
    getRowClass (params) {
      return params.data.unread ? 'unread' : 'read'
    },
    setShowAllOrgs (showAllOrgs) {
      this.$store.dispatch('dashboardNotifications/setShowAllOrgs', showAllOrgs)
    }
  }
}
</script>
<style lang="scss" scoped>
.notification-message-list {
  :deep(.ag-row) {
    &.unread {
      font-weight: bold;
      background-color: #efefef;
    }
  }
}
.quick-filter {
  margin-left: 25px;
  margin-top: 15px !important;
}
</style>
